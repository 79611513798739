/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Navigation from "../navigation/navigation"
import Header from "../header/header"
import "./layout.css"

const Layout = ({ children }) => (
  <React.Fragment>
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Navigation />
          <Header siteTitle={data.site.siteMetadata.title} />
          <div className="layout">
            <main>{children}</main>
            <footer className="py-5 bg-dark">
              <div className="container">
                <p className="m-0 text-center text-white">Copyright &copy; {new Date().getFullYear()}</p>
                <p className="m-0 text-center text-white">North Eagle Corporation s.a.s</p>
                <div className="m-0 text-center text-white small">25 Castelgomberto</div>
                <div className="m-0 text-center text-white small">36070 Vicenza-Italy</div>
                <div className="m-0 text-center text-white small">P.IVA: 03222780243 - R.E.A: VI308656</div>
              </div>
            </footer>
          </div>
          
        </>
      )}
    />
  </React.Fragment>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
