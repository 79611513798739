import { Link } from "react-scroll";
import PropTypes from "prop-types"
import React from "react"
import { Button } from "react-bootstrap"
import './header.css';
import logoImage from '../../images/logo-image.png'

const Header =  () => (
  <header className="text-white header">
    <div className="header-cover">
      <div className="container text-center">
        <div>
          <img alt='logo' src={logoImage} className="header-logo-image"/>
        </div>
        <h2 className="header-title">BUILD NEW OPPORTUNITIES</h2>
        <p className="lead header-lead">
          Invest with us, we fully understand and are committed in respecting the values and privacy of our investors and customers requests. 
        </p>
        <p className="lead header-lead">
          We take this responsibility very seriously.
        </p>
        <Button
            variant="outline-dark"
            className="header-button"
        >
          <Link
            to="contacts"
            smooth={true}
            duration={500}
          >
            MORE INFO
          </Link>
        </Button>
        
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
