import { Link } from "react-scroll";
import React from "react"
import { Navbar } from "react-bootstrap"
import brandImage from '../../images/brand-image.png';
import './navigation.css';

const Navigation =  () => (
  <Navbar collapseOnSelect expand="lg" fixed="top" className="navigation-navbar justify-content-between">
    <Navbar.Brand href="#home">
      <img
        alt='brand'
        src={brandImage}
        className="navigation-brand-image"
      />
    </Navbar.Brand>

    <Navbar.Toggle className="navigation-toggle" aria-controls="responsive-navbar-nav" />

    <Navbar.Collapse id="responsive-navbar-nav" className="navigation-collapse justify-content-end">

      <Link
        to="services"
        className="nav-link navigation-link"
        smooth={true}
        duration={500}
        activeClass="navigation-link-active"
      >
        SERVICES
      </Link>
      <Link
        to="contacts"
        className="nav-link navigation-link"
        smooth={true}
        duration={500}
        activeClass="navigation-link-active"
      >
        CONTACTS
      </Link>
    </Navbar.Collapse>
  </Navbar>
)

export default Navigation
